.app-workout-demo {
    display: flex;
    max-width: 100%;
    height: 100vh;
  }

.subTitle{
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 500; 
  font-family: "Rubik", sans-serif;
  color: #0f1419;
}

.subTitleDescription{
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400; 
  font-family: "Rubik", sans-serif;
  color: rgba(15, 20, 25, 0.7);
  margin-top: 16px;
  margin-bottom: 32px;
}

.group{
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.group div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.label{
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 500; 
  font-family: "Rubik", sans-serif;
  color: #0f1419;
  margin-bottom: 16px;
}




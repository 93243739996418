.exercise{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
}

.exercise-img-container {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background-color: #fafafa;
    margin-right: 16px;
}

.exercise-info{
    display: flex;
    flex-direction: column;
}

.exercise-title{
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    color: #0f1419;
    margin-bottom: 4px;
}

.exercise-description{
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #0f1419;
    margin-bottom: 8px;
}

.exercise-tags{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.exercise-tag{
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #0f1419;
    background-color: 'red';
    width: 100;
}

.exercise-actions{
    display: flex;
    flex-direction: row;
    align-items: center;
}
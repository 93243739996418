.privacy-container {
  height: 100vh;
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: auto;
  flex-direction: column;
  background-color: #fff;
  padding: 2rem;
}

.privacy-container h1 {
  font-size: 2rem;
  margin: 1rem;
}

.privacy-container p {
  font-size: 1.2rem;
  margin: 1rem;
}

.privacy-container a {
  color: #007bff;
}
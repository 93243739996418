.workout-card{
    display: flex;
    flex-direction: column;
    max-width: 49%;
    border: 1px solid #eff3f4;
    margin-bottom: 16px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
}

.workout-card-title{
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    color: #0f1419;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.workout-card-description{
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #3D5266;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
}

.workout-card-fotter{
    display: flex;
    flex-direction: row;
    border-top: 1px solid #eff3f4;
    margin-top: 8px;
    background-color: #f7f9fa;
    overflow: hidden;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.workout-card-fotter-bottom{
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    width: 100%;
    max-width: 50%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.workout-card-fotter-bottom:hover{
    background-color: #eff3f4;
}
.workout-title{
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 32px;
    color: #0f1419;
    margin-bottom: 8px;
    flex-shrink: 1;
    max-width: 60%;
}

.workout-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.workout-header-actions-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
}

.workout-plan-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.workout-plan-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 2rem;
  line-height: 2.25rem;
  color: #0f1419;
}

.workout-plan-description {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: #0f1419;
}

.workout-plan-day-title {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.7rem;
    line-height: 2.25rem;
    color: #0f1419;
}

.semi-bold {
  font-weight: 500;
  color: rgba(15, 20, 25, 0.7);
}

.workout-plan-exercise {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.workout-plan-exercise-name{
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18;
    color: #0f1419;
}

.workout-plan-exercise-work{
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18;
    color: rgba(15, 20, 25, 0.7);
}

@media screen and (max-width: 800px) {
  .workout-plan-title {
    font-size: 1.75rem;
    line-height: 2.15rem;
  }
  .workout-plan-description {
    font-size: 1.1rem;
    line-height: 1.625rem;
  }
  .workout-plan-day-title {

    font-size: 1.6rem;
    line-height: 2rem;
  }
}


.workout-actions-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.workout-description{
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #0f1419;
    margin-bottom: 4px;
}

.workout-series-label{
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    color: #0f1419;
}
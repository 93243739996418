.avatar {
    background-color: #000; /* Black background color */
    color: #fff; /* White text color */
    width: 50px; /* Set the width and height as desired */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Make it circular */
    font-size: 16px; /* Adjust font size as needed */
  }
.sidebarItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 100px;
  align-self: flex-start;
}

.sidebarItemText {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: #0f1419;
  margin-left: 20px;
  margin-right: 20px;
}

.selected {
  font-weight: 700;
}

.sidebarItem:hover {
  background-color: #e8f5fe; /* Twitter-like hover effect */
}

.sidebarItem__icon {
  margin-right: 20px;
}

.sidebarItem__text {
  font-weight: bold;
}

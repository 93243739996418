.fit-avatar {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  background-color: #2583fd;
  color: white;
  cursor: default;
}

.user-avatar {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  color: #183153;
  cursor: default;
  border: 1px solid #eff3f4;
}

.message-grap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #eff3f4;
  padding: 10px 0px 10px;
}

.message-grap-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  padding: 10px 0px 10px;
}

.fit-bubble {
  padding: 10px;
  border-radius: 12px;
  max-width: 300px; 
}

.user-bubble {
  display: "flex";
  align-self: flex-end;
  padding: 10px;
  border-radius: 12px;
  background-color: #eeeeee;
  padding: 10;
  max-width: 300px;
  margin-right: 10px;
}

.workout-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.workout-label{
 font-size: 16px;
 font-weight: 600;
}

.workout-round{
  padding: 10px 0px 10px;
}

.workout-exercise{
  font-size: 16px;
  padding: 10px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.workout-exercise a{
  text-transform: capitalize;
  color: black;
}

.workout-exercise-info{
  display: flex;
  flex-direction: row;
  max-width: 80px;
}

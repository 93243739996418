.main-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-right: 1px solid #eff3f4;
    border-left: 1px solid #eff3f4;
    padding: 20px;
    max-width: 800px;
    background-color: #ffffff;
}

.center-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-right: 1px solid #eff3f4;
    border-left: 1px solid #eff3f4;
    padding: 20px;
    max-width: 800px;
    background-color: #ffffff;
    margin: auto
}

@media screen and (max-width: 800px) {
    .center-content{
        border-right: 0px solid #eff3f4;
        border-left: 0px solid #eff3f4;
    }
}
.icon-button{
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background-color: inherit;
}

.icon-button:not([disabled]):hover {
    background-color: #eee;
}
.workout-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.workout-info-container{
    max-width: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.workout-info-value{
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    color: #0f1419;
    margin-bottom: 4px;
    text-align: center;
}

.workout-info-label{
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #0f1419;
    text-align: center;
}
.sidebar {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    background-color: #ffffff;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  
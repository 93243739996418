.landing {
  height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
}

.landing header {
  padding: 20px;
  width: 100%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.supercoach-landing {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  color: #0a7cff;
  cursor: pointer;
  margin-right: 20px;
}

.landing .main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}

.landing .main-section h3 {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 4rem;
  line-height: 4.8rem;
  color: #2d3748;
  text-align: center;
}

.landing .main-section h3 .blue{
  color: #0a7cff;
}

.landing .main-section p {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: #2d3748;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 5rem;
  opacity: 0.8;
  max-width: 800px;
}

@media screen and (max-width: 700px) {
  .landing .main-section h3 {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }

  .landing .main-section p {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  
}

@media screen and (max-width: 420px) {
  .landing .main-section h3 {
    font-size: 2.8rem;
    line-height: 3rem;
  }

  .landing .main-section p {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  
}

@media screen and (max-width: 370px) {
  .landing .main-section h3 {
    font-size: 2.5rem;
    line-height: 2.6rem;
  }

  .landing .main-section p {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
  
}
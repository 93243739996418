.round{
    border: 1px solid #eff3f4;
    padding: 16px;
    margin-bottom: 24px;
    border-radius: 12px;
}

.round-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.round-title{
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 22px;
    color: #0f1419;
    max-width: 100%;
}

.round-title{
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 22px;
    color: #0f1419;
}

.round-description{
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #0f1419;
    margin-bottom: 24px;
}